<template>
  <div>
    <v-card>
      <v-card-title>Explore Data</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-autocomplete
              dense
              filled
              v-model="project"
              :items="projectNames"
              label="Project"/>
          </v-col>
          <v-col cols="6" class="container">
            <v-autocomplete
              clearable
              dense
              filled
              :items="fields"
              label="Field Assistant"
              @change="applyField"
              @clear="clearExpression"/>
            <v-btn fab text small color="primary"
              @click="getFields">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-textarea
          rows="2"
          dense
          clearable
          counter
          background-color="#eeeeff"
          v-model="expression"
          label="Query"/>
      </v-card-text>
      <v-card-actions>
        <v-row align="center">
        <v-col cols="3">
        <v-btn color="primary"
          @click="search">
          Search
          <v-icon>mdi-file-find</v-icon>
        </v-btn>
        <v-menu open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
            >
              Bulk Actions
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="confirmBulkAssign = true">
              <v-list-item-icon>
                <v-icon>mdi-book-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Assign</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="confirmBulkLabel = true">
              <v-list-item-icon>
                <v-icon>mdi-file-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Label</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="confirmBulkDelete = true">
              <v-list-item-icon>
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Delete</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="showRemoveMetadata = true">
              <v-list-item-icon>
                <v-icon>mdi-tag-remove</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Remove Metadata</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
        <v-text-field
          label="Datum Limit"
          v-model="datumLimit"
          hide-details
          type="number"
        />
        </v-col>
        <v-col cols="auto">
          <div></div>
        </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="2">
            <v-autocomplete
              clearable
              dense
              filled
              :items="files"
              v-model="selectedFile"
              label="File Type"/>
          </v-col>
          <v-col cols="1">
            <v-autocomplete
              dense
              filled
              :items="orders"
              v-model="selectedOrder"
              label="Order"/>
          </v-col>
          <v-col cols="9" class="container">
            <v-autocomplete
              dense
              filled
              multiple
              :items="availableHeaders"
              v-model="selectedHeaders"
              label="Headers"/>
            <v-btn fab text small color="primary"
              @click="getFields">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table :headers="headers" :items="data"
          item-key="curated_datum_id"
          :server-items-length="dataLength"
          :footer-props="{'items-per-page-options':[50, 100]}"
          :options.sync="options"
          :loading="loading"
          loading-text="Loading... Please wait">
          <!-- eslint-disable max-len -->
          <template v-if="project === 'Brandi'" v-slot:item.files="{ item }">
            <img
              v-if="item.files && item.files[selectedFile] && item.files[selectedFile][selectedOrder]"
              :src="`/api/v2/thumbnail/${item.files[selectedFile][selectedOrder]}`"
              :alt="item.files[selectedFile][selectedOrder]"
              :onerror="`this.onerror=null;this.src='/api/v2/file/${item.files[selectedFile][selectedOrder]}';`"
              style="cursor: pointer; max-height: 25px;"
              @click="view(item.files[selectedFile][selectedOrder])"/>
            <v-img
              v-else
              src="../../assets/placeholder.png"
              max-width="150"
              max-height="25"
            >
              <template v-slot>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey"
                    :size="25"
                    :width="4"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </template>
          <template v-else v-slot:item.files="{ item }">
            <img
              v-if="item.files && item.files[selectedFile] && item.files[selectedFile][selectedOrder]"
              :src="`/api/v2/file/${item.files[selectedFile][selectedOrder]}`"
              :alt="item.files[selectedFile][selectedOrder]"
              style="max-height: 25px;"/>
            <v-img
              v-else
              src="../../assets/placeholder.png"
              max-width="150"
              max-height="25"
            >
              <template v-slot>
                <v-row
                  class="ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey"
                    :size="25"
                    :width="4"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn fab text small color="primary"
              @click="selectedItem = item">
              <v-icon small>mdi-file-edit</v-icon>
            </v-btn>
            <v-btn fab text small color="error"
              @click="deleteDatum = item">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <edit-datum
      :value="selectedItem"
      :metadata="selectedMetadata"
      :file="selectedFile"
      :order="selectedOrder"
      @save="saveItem"
      @cancel="selectedItem = null"/>
    <confirm
      label="Bulk Action: Delete"
      :show="confirmBulkDelete"
      @cancel="confirmBulkDelete = false"
      @ok="actionBulkDelete"
      :code="true"/>
    <confirm
      label="Delete Datum"
      :show="deleteDatum !== null"
      @cancel="deleteDatum = null"
      @ok="actionDatumDelete"/>
    <confirm-assign
      :show="confirmBulkAssign"
      @cancel="confirmBulkAssign = null"
      @ok="actionBulkAssign"
      :datasets="subsets"
      />
    <confirm-label
      :show="confirmBulkLabel"
      @cancel="confirmBulkLabel = false"
      @ok="actionBulkLabel"
      :fields="fields"
      :fileType="selectedFile"
      :fileTypes="files"
      />
    <remove-metadata
      :show="showRemoveMetadata"
      @cancel="showRemoveMetadata = false"
      @save="codeBulkRemoveMetadata"
      :availableMetadata="availableRemovableHeaders"
      />
    <confirm
      label="Bulk Action: Metadata Removal"
      :show="confirmBulkMetadataRemoval"
      @cancel="cancelBulkRemoveMetadata"
      @ok="actionBulkRemoveMetadata"
      :code="true"/>
  </div>
</template>
<script>
import Confirm from '../common/Confirm.vue';
import ConfirmAssign from './ConfirmAssign.vue';
import ConfirmLabel from './ConfirmLabel.vue';
import EditDatum from './EditDatum.vue';
import RemoveMetadata from './RemoveMetadata.vue';

export default {
  components: {
    Confirm, ConfirmAssign, ConfirmLabel, EditDatum, RemoveMetadata,
  },
  data() {
    return {
      project: null,
      projects: [],
      projectNames: [],
      subsets: [],
      expression: '',
      fields: [],
      data: [],
      files: ['filled_image', 'template_image', 'labeller', 'labeller_segment', 'labeller_template', 'labeller_segment_template'],
      orders: [0, 1],
      selectedHeaders: ['files', 'encoded.field_type', 'encoded.label', 'encoded.added', 'encoded.modified'],
      selectedFile: 'filled_image',
      selectedOrder: 0,
      selectedItem: null,
      options: {},
      pagination: { rowsPerPage: 50 },
      deleteDatum: null,
      loading: false,
      confirmBulkAssign: false,
      confirmBulkDelete: false,
      confirmBulkLabel: false,

      removeMetadataList: [],
      confirmBulkMetadataRemoval: false,
      showRemoveMetadata: false,

      archived: 0,
      refreshInterval: null,
      datumLimit: 1000000,

    };
  },
  computed: {
    dataLength() {
      if (this.data.length === 0) {
        return 0;
      }
      return this.data[0].total;
    },
    availableRemovableHeaders() {
      const headers = ['files', 'field_type', 'label', 'added', 'modified', 'last_modifier'];
      return this.fields.filter(x => !headers.includes(x));
    },
    availableHeaders() {
      const fh = [{ text: 'files', value: 'files', sortable: true }];
      const fields = {
        field_type: 'field_type', label: 'label', added: 'added', modified: 'modified',
      };
      Object.entries(fields).forEach((x) => {
        if (!this.fields.includes(x[1])) {
          fh.push({ text: x[0], value: `encoded.${this.encode(x[1])}`, sortable: false });
        }
      });
      return this.fields.map(x => ({ text: x, value: `encoded.${this.encode(x)}` })).concat(fh);
    },
    headers() {
      // return this.availableHeaders;
      return this.selectedHeaders
        .map(s => ({ text: this.decode(s.replace('encoded.', '')), value: s, sortable: true }))
        .concat([{
          text: 'actions', value: 'actions', sortable: false, width: '125px',
        }]);
    },
    selectedMetadata() {
      return this.selectedHeaders
        .filter(s => s.startsWith('encoded.') && !(s === 'encoded.added' || s === 'encoded.modified'))
        .map(s => this.decode(s.replace('encoded.', '')));
    },
  },
  methods: {
    loadArchivedImages() {
      if (this.archived > 0 && this.refreshInterval === null) {
        this.refreshInterval = setInterval(() => {
          this.getData();
        }, 30000);
        return;
      }
      if (this.archived === 0 && this.refreshInterval !== null) {
        clearInterval(this.refreshInterval);
      }
    },
    view(img) {
      const imgsrc = `/api/v2/file/${img}`;
      window.open(imgsrc, 'viewwin', 'width=600,height=600');
    },
    encode(str) {
      if (str === null || str === undefined) {
        return null;
      }
      return str.replace('.', '__2E__');
    },
    decode(str) {
      if (str === null || str === undefined) {
        return null;
      }
      return str.replace('__2E__', '.');
    },
    clearExpression() {
      this.expression = '';
    },
    applyField(event) {
      if (event === undefined) {
        return;
      }
      const exp = this.expression === null ? '' : this.expression;
      this.expression = exp + ((exp.length === 0 || exp[exp.length - 1] === ' ') ? '' : ' ') + event;
    },
    cancelBulkRemoveMetadata() {
      this.confirmBulkMetadataRemoval = false;
      this.removeMetadataList = [];
    },
    codeBulkRemoveMetadata(metadataList) {
      this.showRemoveMetadata = false;
      this.confirmBulkMetadataRemoval = true;
      this.removeMetadataList = metadataList;
    },
    async actionBulkRemoveMetadata() {
      this.confirmBulkMetadataRemoval = false;
      await this.$http.post('/api/v2/bulk/remove_metadata', {
        project: this.project,
        dataset: null,
        query: this.expression,
        metadata: { remove: this.removeMetadataList },
      });
      this.$toast.success('Bulk remove metadata queued');
      this.removeMetadataList = [];
    },
    async search() {
      await this.getData();
      this.options.page = 1;
    },
    async getProjects() {
      const response = await this.$http.get('/api/v2/project');
      this.projects = response.data;
      this.projectNames = this.projects.map(project => project.project_name);
      this.project = this.projectNames.find(x => x === 'Sophia');
    },
    async getFields() {
      const response = await this.$http.post('/api/v2/data/fields', { project: this.project });
      this.fields = response.data.filter(item => !['date_added', 'date_modified'].includes(item));
    },
    async getData() {
      try {
        let archived = 0;
        this.data = [];
        this.loading = true;
        const sortBy = this.options.sortBy;
        const sortDesc = this.options.sortDesc;
        const page = this.options.page;
        const itemsPerPage = this.options.itemsPerPage;

        const response = await this.$http.post('/api/v2/data/query', {
          project: this.project,
          expression: this.expression,
          offset: itemsPerPage * (page - 1),
          limit: itemsPerPage,
          datum_limit: parseInt(this.datumLimit, 10),
          sort: sortBy.length > 0 ? this.decode(sortBy[0]).replace('encoded.', 'metadata.') : null,
          asc: sortDesc.length > 0 ? sortDesc[0] : null,
        });
        /* eslint-disable */
        for (const datum of response.data) {
          const encoded = {};
          if(datum.status === 'archived'){
            archived = archived + 1
          }
          /* eslint-disable */
          for (const key in datum.metadata) {
            if (key === 'date_added' || key === 'date_modified') {
              encoded[this.encode(key.replace('date_',''))] = new Date(datum.metadata[key]).toLocaleDateString('en-ZA');
              continue;
            }
            encoded[this.encode(key)] = datum.metadata[key];
          }
          datum.encoded = encoded;
        }
        this.data = response.data;
        this.archived = archived;
        this.loadArchivedImages()
      } finally {
        this.loading = false
      }
    },
    async getSubsets() {
      const response = await this.$http.get('/api/v2/subset', { project: this.project });
      this.subsets = response.data.filter(s => s.project_name === this.project).map(s => s.subset_name);
    },
    async saveItem(item, deletedFields, hasNewFields) {
      await this.$http.post('/api/v2/data/metadata', {
        project: this.project,
        curated_datum_id: item.curated_datum_id,
        metadata: item.metadata,
        deleted_fields: deletedFields,
        has_new_fields: hasNewFields
      })
      this.$toast.success('Datum saved');
      this.selectedItem = null
      this.getData();
    },
    async actionBulkAssign(subset) {
      this.confirmBulkAssign = false
      await this.$http.post('/api/v2/bulk/assign', { 
        project: this.project,
        dataset: subset,
        query: this.expression,
      })
      this.$toast.success('Bulk assign queued');
    },
    async actionBulkDelete() {
      this.confirmBulkDelete = false
      await this.$http.post('/api/v2/bulk/delete', { 
        project: this.project,
        dataset: null,
        query: this.expression,
      })
      this.$toast.success('Bulk delete queued');
    },
    async actionBulkLabel(targetField, item) {
      await this.$http.post('/api/v2/bulk/label', { 
        project: this.project,
        dataset: null,
        query: this.expression,
        target_field: targetField,
        metadata: item,
      })
      this.confirmBulkLabel = false
      this.$toast.success('Bulk label queued');
      await this.getFields();
    },
    async actionDatumDelete() {
      await this.$http.post('/api/v2/data/delete', { 
        curated_datum_id: this.deleteDatum.curated_datum_id,
      })
      this.deleteDatum = null
      this.$toast.success('Datum deleted');
      await this.getFields();
      this.getData();
    },
  },
  mounted() {
    this.getProjects();
    this.getSubsets();
  },
  watch: {
    options: {
      handler () {
        this.getData()
      },
      deep: true,
    },
    project: {
      handler () {
        this.getFields()
        this.getData()
        this.getSubsets()
        let project = this.projects.find(o => o.project_name === this.project);
        if ( project ){ 
          this.selectedFile = project.file_type; 
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
button.v-btn {
  margin-left: 1px;
  margin-right: 1px;
}
div.container {
  display: flex;
  justify-items: center;
}
</style>
